@import '/src/styles/index.scss';

.button {
  border: 1px solid $gray-30;
  border-radius: $global-radius;
  min-height: rem-calc(48);
  padding: 0.5rem _var(--global-padding);
  gap: rem-calc(12);
  background-color: $white;
  color: #5f6368;
  font-size: $small-font-size;
  display: inline-flex;
  align-items: center;
  width: 100%;

  & > svg {
    width: rem-calc(14);
    height: rem-calc(14);
  }
}

.text {
  font-size: $small-font-size;
  color: $gray-40;
  padding-top: $global-xsm-padding;

  > a {
    color: $blue-primary;
    text-decoration: underline;
  }
}
